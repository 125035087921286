var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"act-box"},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"left-side-menu"},[_c('div',{staticClass:"slimScrollDiv mm-active",staticStyle:{"position":"relative","overflow":"hidden","width":"auto","height":"1129px"}},[_c('div',{staticClass:"slimscroll-menu mm-show",staticStyle:{"overflow":"hidden","width":"auto","height":"1129px"}},[_c('div',{staticClass:"mm-active",attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu mm-show",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"mm-active"},[_c('router-link',{staticClass:"active",attrs:{"to":{
                    name: 'ACTList'
                  }}},[_c('i',{staticClass:"dripicons-pencil"}),_c('span',[_vm._v(" Take ACT Tests ")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'ACTHistory'
                  }}},[_c('i',{staticClass:"dripicons-pamphlet"}),_c('span',[_vm._v(" ACT Exam History ")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'ACTExplanations'
                  }}},[_c('i',{staticClass:"dripicons-document"}),_c('span',[_vm._v(" ACT Test Explanations ")])])],1)])]),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"slimScrollBar",staticStyle:{"background":"rgb(158, 165, 171)","width":"8px","position":"absolute","top":"0px","opacity":"0.4","display":"none","border-radius":"7px","z-index":"99","right":"1px","height":"1129px"}}),_c('div',{staticClass:"slimScrollRail",staticStyle:{"width":"8px","height":"100%","position":"absolute","top":"0px","display":"none","border-radius":"7px","background":"rgb(51, 51, 51)","opacity":"0.2","z-index":"90","right":"1px"}})])]),_c('div',{staticClass:"content-page",staticStyle:{"height":"auto !important","min-height":"0px !important"}},[_c('div',{staticClass:"container-fluid",staticStyle:{"height":"auto !important"}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pl-0 pr-0"},[_c('div',{staticClass:"card-box pl-1 pr-1 pt-2 pb-2 mb-1"},[_c('div',{staticClass:"table-responsive pl-3 pr-3 pt-1 pb-3 mt-1"},[_c('p',[_vm._v("Please click the links below to view ACT test explanations.")]),_c('table',{staticClass:"table table-bordered table-striped table-hover text-center"},[_vm._m(2),_vm._l((83),function(i){return _c('tr',{key:i},[_c('td',[_vm._v("1")]),_c('td',[_vm._v("December 2020 Form D03")]),_vm._m(3,true)])})],2)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-custom"},[_c('ul',{staticClass:"list-unstyled topnav-menu float-right mb-0",attrs:{"id":"ajaxlogin"}},[_c('li',{staticClass:"dropdown notification-list"},[_c('a',{staticClass:"nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light",attrs:{"data-toggle":"dropdown","href":"#","role":"button","aria-haspopup":"false","aria-expanded":"false"}},[_c('span',{staticClass:"pro-user-name ml-1"},[_vm._v(" ACT Test ")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right profile-dropdown"},[_c('div',{staticClass:"dropdown-item noti-title"},[_c('h6',{staticClass:"m-0"},[_vm._v(" Welcome ! ")])]),_c('a',{staticClass:"dropdown-item notify-item",attrs:{"href":"/exam/profile.php"}},[_c('i',{staticClass:"dripicons-user"}),_c('span',[_vm._v("My Account")])]),_c('a',{staticClass:"dropdown-item notify-item",attrs:{"href":"/exam/index.php"}},[_c('i',{staticClass:"dripicons-pencil"}),_c('span',[_vm._v("Take ACT Test")])]),_c('a',{staticClass:"dropdown-item notify-item",attrs:{"href":"/exam/history.php"}},[_c('i',{staticClass:"dripicons-pamphlet"}),_c('span',[_vm._v("Exam History")])]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item notify-item",attrs:{"href":"/user/logout.php"}},[_c('i',{staticClass:"dripicons-exit"}),_c('span',[_vm._v("Logout")])])])])]),_c('ul',{staticClass:"list-unstyled menu-left topnav-menu mb-0"},[_c('li',[_c('a',{staticClass:"logo",attrs:{"href":"/","title":"home"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"/assets/images/logo-light.png","alt":"","height":"42"}})]),_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"/assets/images/logo-sm.png","alt":"","height":"45"}})])])]),_c('li',[_c('a',{staticClass:"button-menu-mobile navbar-toggle"},[_c('div',{staticClass:"lines"},[_c('span'),_c('span'),_c('span')])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box"},[_c('h1',{staticClass:"page-title"},[_vm._v("ACT Test Explanations")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"text-light bg-primary"},[_c('th',[_vm._v("No")]),_c('th',[_vm._v("ACT Exam Name")]),_c('th',[_vm._v("Test Explanations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"td-section"},[_c('a',{attrs:{"href":"/exam/start.php?type=english&examid=202012&mode=0"}},[_vm._v("English")]),_c('a',{attrs:{"href":"/exam/start.php?type=math&examid=202012&mode=0"}},[_vm._v("Math")]),_c('a',{attrs:{"href":"/exam/start.php?type=reading&examid=202012&mode=0"}},[_vm._v("Reading")]),_c('a',{attrs:{"href":"/exam/start.php?type=science&examid=202012&mode=0"}},[_vm._v("Science")])])
}]

export { render, staticRenderFns }